body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.custom-checkbox label {
  position: relative;
  display: inline-block;

  /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
  /* padding-left: 25px !important; */
}

.custom-checkbox label::before,
.custom-checkbox label::after {
  position: absolute;
  content: "";

  /*Needed for the line-height to take effect*/
  display: inline-block;
}

/*Outer box of the fake checkbox*/
.custom-checkbox label::before {
  height: 17px;
  width: 17px;
  border-radius: .21428571rem;
  border: 1px solid #d4d4d5;
  left: -20px;

  /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
   *to vertically center it.
   */
  top: 1px;
}

/*Hide the checkmark by default*/
.custom-checkbox input[type="checkbox"]+label::after {
  content: none;
}

/*Unhide on the checked state*/
.custom-checkbox input[type="checkbox"]:checked+label::after {
  height: 5px;
  width: 9px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-45deg);
  left: -16px;
  top: 6px;
  content: ""
}

.custom-checkbox input[type="checkbox"]:indeterminate+label::after {
  height: 5px;
  width: 9px;
  border-bottom: 2px solid;
  left: -16px;
  top: 6px;
  content: "";
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.custom-checkbox input[type="checkbox"]:focus+label::before {
  outline: rgb(59, 153, 252) auto 5px;
}

/* Force revert to compact styling if a button is inside a menu */
.ui.menu:not(.vertical) .item>.button.compact {
  padding: .58928571em 1.125em .58928571em;
  font-size: 0.9em;
}

.ui.vertical.menu .header.item {
  margin-top: 10px !important;
}

.ui.vertical.menu .header.item:hover {
  background: transparent !important;
}

 .react-datepicker__input-time-container{
  margin: 0 !important;
  display: flex;
  align-items: center;
  padding: 10px;
}

 .react-datepicker__input-time-container .react-datepicker-time__input-container{
  flex: 1;
  display: flex!important;
  flex-direction: row;
  justify-content: stretch;
}

 .react-datepicker-time__input{
  flex: 1;
 }

 .react-datepicker__input-time-container .react-datepicker-time__input-container input{
  width: 100%;
}

